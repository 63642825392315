.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-display: sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from { 
    opacity: 0;
    }
  to   { 
    opacity: 1;
  }
}

.animation {
  animation: shakeAnim 1s 1;
}

@keyframes shakeAnim {
  0% {left: 0}
  1% {left: -3px}
  2% {left: 5px}
  3% {left: -8px}
  4% {left: 8px}
  5% {left: -5px}
  6% {left: 3px}
  7% {left: 0}
}

@keyframes glow {
    40% {
      text-shadow: 0 0 8px #fff;
    }
}

.pointerOverEffect {
  opacity: 1;
  transition: all .3s ease-in-out;
}

.pointerOverEffect:hover {
  opacity: 0.6;
}